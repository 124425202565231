<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-select v-model="search.templateGroupId" placeholder="请选择模板类型" size="small" @change="queryInstitutionTemplate(search.templateGroupId)">
          <el-option v-for="item in selector.templateGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="search.institutionTemplateId" placeholder="请选择企业模板" size="small">
          <el-option v-for="item in selector.institutionTemplateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input v-model="search.name" size="small" maxlength="128" placeholder="请输入企业名" clearable></el-input>
        <el-button size="small" type="primary" @click="searchData">搜索</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`企业列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column label="所有者" width="112">
          <template #default="scope">
            <div style="text-align: center;font-size: 12px;" v-if="scope.row.member">
              <el-image style="width: 40px; height: 40px;border-radius: 20px;" :src="scope.row.member.image" fit="contain" :preview-src-list="[scope.row.member.image]" :initial-index="1"></el-image>
              <div>{{scope.row.member.name}}</div>
            </div>
            <div v-else>无</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名"></el-table-column>
        <el-table-column label="模板类型">
          <template #default="scope">{{scope.row.templateGroup ? scope.row.templateGroup.name : '无'}}</template>
        </el-table-column>
        <el-table-column label="企业模板">
          <template #default="scope">{{scope.row.templateGroup && scope.row.templateGroup.institutionTemplate ? scope.row.templateGroup.institutionTemplate.name : '无'}}</template>
        </el-table-column>
        <el-table-column prop="cardCount" label="人员数量"></el-table-column>
        <el-table-column prop="setmealRecord ? setmealRecord.setmeal.name : '无'" label="套餐">
          <template #default="scope">{{scope.row.setmealRecord ? scope.row.setmealRecord.setmeal.name : '无'}}</template>
        </el-table-column>
        <el-table-column label="提现规则" width="200">
          <template #default="scope">
            <div>最低提现：¥{{scope.row.platformWithdrawRule.minFee}}</div>
            <div>单次提现：¥{{scope.row.platformWithdrawRule.withdrawOnce}}</div>
            <div>单日提现：¥{{scope.row.platformWithdrawRule.withdrawDay}}</div>
            <div>手续费：{{scope.row.platformWithdrawRule.withdrawRate*100}}%</div>
            <div>订单锁定时长：{{scope.row.platformWithdrawRule.orderLockDays}}天</div>
            <div>提现说明：{{scope.row.platformWithdrawRule.tip}}</div>
          </template>
        </el-table-column>
        <el-table-column label="新增时间" width="100">
          <template #default="scope">{{timeFormat(scope.row.addTime)}}</template>
        </el-table-column>
        <el-table-column label="过期时间" width="100">
          <template #default="scope">{{timeFormat(scope.row.setmealOverdueTime)}}</template>
        </el-table-column>
        <el-table-column label="设置">
          <template #default="scope">
            <div>{{scope.row.showSearch ? '显示搜索栏' : '不显示搜索栏'}}</div>
            <div>{{scope.row.showSupporter ? '显示萌名片' : '不显示萌名片'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210">
          <template #default="scope">
            <el-button type="text" size="small" @click="modifyInstitutionTemplate(scope.row)">修改模板</el-button>
            <el-button type="text" size="small" @click="copyInstitution(scope.row)">复制小程序</el-button>
            <el-button type="text" size="small" @click="modifySettings(scope.row)">设置</el-button>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!--修改企业模板-->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <el-form-item label="模板类型" prop="templateGroupId">
        <el-select v-model="dialog.data.templateGroupId" placeholder="请选择模板类型" size="small" @change="queryDialogInstitutionTemplate(dialog.data.templateGroupId)" clearable>
          <el-option v-for="item in dialog.templateGroupList" :label="item.name" :key="item.id" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业模板" prop="institutionTemplateId" :rules="[
        {required: true, message: '企业模板不能为空', trigger: 'blur'}
      ]" v-if="dialog.data.templateGroupId">
        <el-select v-model="dialog.data.institutionTemplateId" placeholder="请选择企业模板" size="small" clearable>
          <el-option v-for="item in dialog.institutionTemplateList" :label="item.name" :key="item.id" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirmInstitutionTemplate" size="small">确定</el-button>
    </template>
  </el-dialog>
  <!--复制小程序-->
  <el-dialog v-model="copyInstitutionDialog.visible" title="复制小程序" destroy-on-close>
    <el-form ref="copyInstitutionForm" :model="copyInstitutionDialog.data" label-width="auto">
      <!--企业id-->
      <el-form-item label="来源企业id" prop="fromIid" :rules="[
        {required:true, message: '企业id不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="copyInstitutionDialog.data.fromIid" size="small" type="number" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="copyInstitutionDialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirmCopy" size="small">确定</el-button>
    </template>
  </el-dialog>
  <!--设置-->
  <el-dialog v-model="settingsDialog.visible" :title="settingsDialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="settingsDialog.data" label-width="auto">
      <el-form-item label="萌名片广告" prop="showSupporter">
        <el-select v-model="settingsDialog.data.showSupporter" placeholder="请选择是否显示萌名片广告" size="small" clearable>
          <el-option label="显示广告" key="1" value="1"></el-option>
          <el-option label="不显示广告" key="0" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="搜索栏" prop="showSearch">
        <el-select v-model="settingsDialog.data.showSearch" placeholder="请选择是否显示搜索栏" size="small" clearable>
          <el-option label="显示搜索栏" key="1" value="1"></el-option>
          <el-option label="不显示搜索栏" key="0" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低提现" prop="minFee" :rules="[
        {required:true, message: '最低提现不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.minFee" size="small" type="number" clearable></el-input>
      </el-form-item>
      <el-form-item label="单日提现" prop="withdrawOnce" :rules="[
        {required:true, message: '单日提现不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.withdrawOnce" size="small" type="number" clearable></el-input>
      </el-form-item>
      <el-form-item label="单次提现" prop="withdrawDay" :rules="[
        {required:true, message: '单次提现不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.withdrawDay" size="small" type="number" clearable></el-input>
      </el-form-item>
      <el-form-item label="手续费" prop="withdrawRate" :rules="[
        {required:true, message: '手续费不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.withdrawRate" size="small" type="number" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单锁定时长" prop="orderLockDays" :rules="[
        {required:true, message: '订单锁定时长不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.orderLockDays" size="small" type="number" clearable></el-input>
      </el-form-item>
      <el-form-item label="提现说明" prop="tip" :rules="[
        {required:true, message: '提现说明不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="settingsDialog.data.tip" size="small" type="text" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="settingsDialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirmSettings" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElSelect, ElOption, ElTableColumn, ElInput, ElImage, ElDialog, ElForm, ElFormItem } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
import type from '@/common/utils/type'
import { timeFormat } from '@/common/utils/function.js'

function table () {
  const search = reactive({
    templateGroupId: '',
    institutionTemplateId: '',
    name: ''
  })
  const selector = reactive({
    templateGroupList: [{
      id: '',
      name: '不限模板类型'
    }],
    institutionTemplateList: [{
      id: '',
      name: '不限企业模板'
    }]
  })
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/institution/list', {
        templateGroupId: search.templateGroupId,
        institutionTemplateId: search.institutionTemplateId,
        name: search.name,
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.institutionList, res.totalCount)
          resolve({
            list: res.institutionList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }
  /**
   * 搜索模板
   */
  const searchData = () => {
    table.value.reload()
  }
  /**
     * 查询枚举--模板类型
     */
  const queryType = () => {
    return new Promise((resolve) => {
      axios('/manager/template/group/list/all', { type: type().templateGroupType.institution })
        .then(res => {
          res.templateGroupList.forEach(item => {
            selector.templateGroupList.push(item)
          })
          resolve()
        }).catch(_ => { })
    })
  }
  /**
   * 查询企业模板
   */
  const queryInstitutionTemplate = templateGroupId => {
    if (!templateGroupId) {
      selector.institutionTemplateList = [{
        id: '',
        name: '不限企业模板'
      }]
      search.institutionTemplateId = ''
      return
    }
    return new Promise((resolve) => {
      axios('/manager/institution/template/list/all', { templateGroupId })
        .then(res => {
          res.institutionTemplateList.forEach(item => {
            selector.institutionTemplateList.push(item)
          })
          resolve()
        }).catch(_ => { })
    })
  }
  onMounted(async () => {
    await queryType()
    table.value.reload()
  })

  return {
    search,
    selector,
    table,
    queryData,
    totalCount,
    searchData,
    queryInstitutionTemplate
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    templateGroupList: [{
      id: '',
      name: '不使用模板'
    }],
    institutionTemplateList: [],
    data: {
      iid: '',
      templateGroupId: '',
      institutionTemplateId: ''
    }
  })
  const queryDialogInstitutionTemplate = templateGroupId => {
    return new Promise((resolve) => {
      axios('/manager/institution/template/list/all', { templateGroupId })
        .then(res => {
          dialog.institutionTemplateList = []
          res.institutionTemplateList.forEach(item => {
            dialog.institutionTemplateList.push(item)
          })
          resolve()
        }).catch(_ => { })
    })
  }
  return {
    ruleForm,
    dialog,
    queryDialogInstitutionTemplate
  }
}

function copyInstitutionDialog () {
  const copyInstitutionForm = ref(null)
  const copyInstitutionDialog = reactive({
    visible: false,
    data: {
      fromIid: '',
      targetIid: ''
    }
  })
  return {
    copyInstitutionForm,
    copyInstitutionDialog
  }
}

function settingsDialog () {
  const ruleForm = ref(null)
  const settingsDialog = reactive({
    visible: false,
    title: '修改设置',
    data: {
      iid: '',
      showSupporter: 1,
      showSearch: 1,
      minFee: '',
      withdrawOnce: '',
      withdrawDay: '',
      withdrawRate: '',
      orderLockDays: '',
      tip: ''
    }
  })
  return {
    ruleForm,
    settingsDialog
  }
}

function operation (t, d, sd, cid) {
  /**
    * 修改企业模板
    */
  const modifyInstitutionTemplate = institution => {
    d.dialog.templateGroupList = t.selector.templateGroupList.map((templateGroup, index) => {
      if (index === 0) {
        return {
          id: '',
          name: '不使用模板'
        }
      }
      return templateGroup
    })
    if (institution.templateGroup) {
      d.queryDialogInstitutionTemplate(institution.templateGroup.id)
    }
    d.dialog.visible = true
    d.dialog.title = '修改企业模板'
    d.dialog.data = {
      iid: institution.id,
      templateGroupId: institution.templateGroup ? institution.templateGroup.id : '',
      institutionTemplateId: institution.templateGroup && institution.templateGroup.institutionTemplate ? institution.templateGroup.institutionTemplate.id : ''
    }
  }
  const confirmInstitutionTemplate = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submitInstitutionTemplate()
    })
  }
  const submitInstitutionTemplate = () => {
    axios('/manager/institution/institution/template/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  const copyInstitution = institution => {
    cid.copyInstitutionDialog.visible = true
    cid.copyInstitutionDialog.data = {
      fromIid: '',
      targetIid: institution.id
    }
  }
  const confirmCopy = () => {
    cid.copyInstitutionForm.value.validate(valid => {
      if (!valid) {
        return
      }
      axios('/manager/institution/deep/copy', cid.copyInstitutionDialog.data)
        .then(res => {
          window.$message({
            message: '操作成功',
            type: 'success'
          })
          cid.copyInstitutionDialog.visible = false
          t.table.value.reloadCurrent()
        }).catch(_ => { })
    })
  }
  const modifySettings = institution => {
    sd.settingsDialog.visible = true
    sd.settingsDialog.data = {
      iid: institution.id,
      showSupporter: institution.showSupporter ? '1' : '0',
      showSearch: institution.showSearch ? '1' : '0',
      minFee: institution.platformWithdrawRule.minFee,
      withdrawOnce: institution.platformWithdrawRule.withdrawOnce,
      withdrawDay: institution.platformWithdrawRule.withdrawDay,
      withdrawRate: institution.platformWithdrawRule.withdrawRate,
      orderLockDays: institution.platformWithdrawRule.orderLockDays,
      tip: institution.platformWithdrawRule.tip
    }
  }
  const confirmSettings = () => {
    sd.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      axios('/manager/institution/settings/save', sd.settingsDialog.data)
        .then(res => {
          window.$message({
            message: '操作成功',
            type: 'success'
          })
          sd.settingsDialog.visible = false
          t.table.value.reloadCurrent()
        }).catch(_ => { })
    })
  }

  return {
    modifyInstitutionTemplate,
    confirmInstitutionTemplate,
    copyInstitution,
    confirmCopy,
    modifySettings,
    confirmSettings
  }
}

export default {
  components: { ElButton, ElSelect, ElOption, ElTableColumn, ElInput, ElImage, ElDialog, ElForm, ElFormItem },

  setup () {
    const t = table()
    const d = dialog()
    const sd = settingsDialog()
    const cid = copyInstitutionDialog()
    const o = operation(t, d, sd, cid)
    return {
      ...t,
      ...d,
      ...sd,
      ...o,
      ...cid,
      timeFormat
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
.drawer-button {
  padding: 10px 0;
}
</style>
