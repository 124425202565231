<script>
import { h, ref } from 'vue'
import institution from './components/institution.vue'
import { ElTabs, ElTabPane } from 'element-plus'
export default {
  setup () {
    const institutionCom = ref(null)
    const tabs = {
      activeName: 'institution',
      tabs: [
        { label: '企业管理', name: 'institution', component: institution, ref: institutionCom }
      ]
    }

    return {
      tabs
    }
  },
  render () {
    return h(
      ElTabs, {
        modelValue: this.tabs.activeName
      }, () => this.tabs.tabs.map(item => {
        return h(ElTabPane, {
          name: item.name,
          label: item.label
        }, () => [
          h(item.component, {
            ref: item.ref || ''
          })
        ])
      })
    )
  }
}
</script>
<style lang="scss" scoped>
</style>
